@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* regular */
@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Regular.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* light */
@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* medium */
@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Medium.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}




